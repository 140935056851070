import { Dropdown } from '@mui/base/Dropdown'
import { IconChevronLeft } from '@tabler/icons-react'
import { Navigate, useSearch } from '@tanstack/react-router'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { IconCeles } from '~/assets/icons'
import { IconSpain } from '~/assets/icons/IconSpain'
import { IconUnitedStates } from '~/assets/icons/IconUnitedStates'
import { Menu } from '~/components/UI/Menu'
import { MenuButton } from '~/components/UI/MenuButton'
import { MenuItem } from '~/components/UI/MenuItem'
import { useTenantsQuery } from '~/hooks/queries/useTenantsQuery'
import { signOut } from '~/lib/firebase'
import { useAuthContext } from '~/providers/AuthProvider'
import { LoginBackground, RegisterForm } from './components'
import type { LoginSearchParams } from './types'

export const LoginPage = () => {
  const { i18n } = useTranslation('login')

  const { isAuthenticatedWithTenant, isAuthenticated, setTenantIdToLoad } = useAuthContext()

  const shouldFetchTenants = isAuthenticated && !isAuthenticatedWithTenant
  const tenantsQuery = useTenantsQuery(shouldFetchTenants)

  const hasOnlyOneTenantOption = tenantsQuery.data?.tenants.length === 1

  const search = useSearch({
    from: '/login',
    select: (search: LoginSearchParams) => search,
  })

  useEffect(() => {
    // if has only 1 tenant, set it as the tenant to load
    if (hasOnlyOneTenantOption && tenantsQuery.data) {
      setTenantIdToLoad(tenantsQuery.data.tenants[0].global_id)
    }
  }, [setTenantIdToLoad, hasOnlyOneTenantOption, tenantsQuery.data])

  if (isAuthenticatedWithTenant) {
    return <Navigate to={search?.redirect || '/dashboard'} />
  }

  return (
    <div className="relative h-screen overflow-hidden">
      <div className="z-20 flex items-start justify-between p-5 md:pl-5 md:pr-2">
        {isAuthenticated && (
          <button className="md:hidden" onClick={signOut}>
            <IconChevronLeft />
          </button>
        )}

        <IconCeles className="absolute left-1/2 top-28 w-12 -translate-x-1/2 transform md:static md:h-10 md:w-7 md:translate-x-0" />
        <Dropdown>
          <MenuButton
            className="ml-auto flex items-start space-x-2 !py-1"
            slotProps={{ icon: { className: 'hidden size-5 md:block' } }}
          >
            {i18n.language === 'es' ? (
              <IconSpain className="size-4" />
            ) : (
              <IconUnitedStates className="size-4" />
            )}
          </MenuButton>
          <Menu
            slotProps={{
              root: {
                className: 'z-10',
              },
            }}
          >
            <MenuItem className="space-x-3" onClick={() => i18n.changeLanguage('es')}>
              <IconSpain /> <span>Español</span>
            </MenuItem>
            <MenuItem className="space-x-3" onClick={() => i18n.changeLanguage('en')}>
              <IconUnitedStates />
              <span>English</span>
            </MenuItem>
          </Menu>
        </Dropdown>
      </div>

      <LoginBackground className="absolute bottom-0 -z-10 scale-175 md:scale-100" />

      <RegisterForm />
    </div>
  )
}
